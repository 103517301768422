import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import '../css/NavMenu.css'; // Import the CSS

import HomePage from './HomePage';
import HelpPage from './HelpPage';
import TreeViewer from './TreeViewer';

const NavMenu = () => {
  return (
    <Router>
      <nav className="navbar">
        <ul className="nav-links">
          <li>
            <NavLink 
              to="/" 
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink 
              to="/help" 
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
            >
              Help
            </NavLink>
          </li>
        </ul>
      </nav>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/help" element={<HelpPage />} />
      </Routes>
    </Router>
  );
};

export default NavMenu;
