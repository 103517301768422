import React, { useState } from 'react';
import '../css/SearchBar.css';

function SearchBar() {
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [hoveredNode, setHoveredNode] = useState(null); // To track the hovered node

  const handleInputChange = (event) => setQuery(event.target.value);

  const handleSearch = async () => {
    try {
      const response = await fetch(`https://homalab.com/search/ccam?q=${query}`);
      
      // const response = await fetch(`http://backend/search/ccam?q=${query}`);
      // http://backend/search/ccam?q=${query}
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') handleSearch();
  };

  const formatCurrency = (value) => {
    if (!value) return null;
    if (value === 'Non pris en charge') return value;
    return value.toString().replace('.', ',') + ' €';
  };

  const getHighlightedText = (text, keywords) => {
    if (!text || typeof text !== 'string' || !keywords || !keywords.length) {
      return text;
    }

    let fragments = [text];

    keywords.forEach((keyword) => {
      const newFragments = [];

      fragments.forEach((fragment) => {
        if (typeof fragment === 'string') {
          const parts = fragment.split(new RegExp(`(${keyword})`, 'gi'));
          parts.forEach((part) => {
            if (part.toLowerCase() === keyword.toLowerCase()) {
              newFragments.push(<span style={{ fontWeight: 'bold' }}>{part}</span>);
            } else {
              newFragments.push(part);
            }
          });
        } else {
          newFragments.push(fragment);
        }
      });

      fragments = newFragments;
    });

    return fragments;
  };

  // Fonction pour séparer avant et après les deux-points
  const splitLastNodeText = (lastNode) => {
    if (!lastNode) return { beforeColon: '', afterColon: '' };
    const parts = lastNode.split(':');
    return {
      beforeColon: parts[0], // Partie avant les deux-points
      afterColon: parts.slice(1).join(':').trim(), // Partie après les deux-points
    };
  };

  // Fonction pour copier le code dans le presse-papiers avec gestion d'erreur
  const copyToClipboard = async (code) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(code);
        console.log('Code copié avec succès :', code);
      } catch (err) {
        console.error('Échec de la copie du code :', err);
      }
    } else {
      // Fallback pour les anciens navigateurs ou contextes non sécurisés
      const textArea = document.createElement('textarea');
      textArea.value = code;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        console.log('Code copié avec la méthode de secours :', code);
      } catch (err) {
        console.error('Échec de la copie du code avec la méthode de secours :', err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <div>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Entrer ici des mots clés ou des codes CCAM..."
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      {searchResults.length > 0 && (
        <div className="search-results">
          <table className="search-results-table">
            <thead>
              <tr>
                <th>Chapitre</th>
                <th>Code</th>
                <th>Intitulé CCAM</th>
                <th>Tarif</th>
                <th>Exo. TM</th>
                <th>Activité(s)</th>
                <th>Regroupement</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((result, index) => {
                const highlightedTextDescription =
                  result.highlight && result.highlight.Keywords
                    ? getHighlightedText(result.Description, result.highlight.Keywords)
                    : result.Description;

                const highlightedTextCode =
                  result.Code === query ? getHighlightedText(result.Code, [query]) : result.Code;

                // Extraire le dernier nœud de chaque "Treemap" du résultat
                const lastNode =
                  result.Treemap && result.Treemap.length > 0
                    ? `${result.Treemap[result.Treemap.length - 1].key}: ${result.Treemap[result.Treemap.length - 1].value}`
                    : 'Aucun dernier nœud disponible';

                // Séparer avant et après les deux-points
                const { beforeColon, afterColon } = splitLastNodeText(lastNode);

                return (
                  <tr key={result.Code}>
                    {/* Affichage du Chapitre avec tooltip */}
                    <td>
                      <div
                        onMouseEnter={() => setHoveredNode(index)}
                        onMouseLeave={() => setHoveredNode(null)}
                        style={{ position: 'relative' }}
                      >
                        {/* Partie visible avant les deux-points */}
                        <span>{beforeColon}</span>

                        {/* Affichage du tooltip lors du survol (à droite) */}
                        {hoveredNode === index && afterColon && (
                          <div
                            style={{
                              position: 'absolute',
                              backgroundColor: '#fff',
                              border: '1px solid #ddd',
                              padding: '5px',
                              borderRadius: '4px',
                              top: '0',
                              left: '110%',
                              whiteSpace: 'nowrap',
                              zIndex: 1,
                              boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
                            }}
                          >
                            {afterColon}
                          </div>
                        )}
                      </div>
                    </td>
                    {/* Code avec fonctionnalité de copie */}
                    <td onClick={() => copyToClipboard(result.Code)}>
                      {highlightedTextCode}
                    </td>
                    <td>{highlightedTextDescription}</td>
                    <td>{formatCurrency(result['Other Data']['Tarif_S1'])}</td>
                    <td>{result['Other Data']['Exo.TM']}</td>
                    <td>{result['Other Data']['Activité']}</td>
                    <td>{result['Other Data']['Regroupement']}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default SearchBar;
