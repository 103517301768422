import React from 'react';
import '../css/Footer.css'; // Import the CSS

function Footer() {
  return (
    <footer className="global-footer">
      <p>Ce site est réalisé par <a href="https://www.societe.com/societe/scaleflow-880754817.html" rel="noopener noreferrer">Scaleflow</a> avec <span className="heart-icon">❤️</span> en 2023 &copy; tous droits réservés</p>
    </footer>
  );
}

export default Footer;