import './App.css';
import React from 'react';
import SearchBar  from './component/SearchBar';
import Footer from './component/Footer';
import NavMenu from './component/NavMenu';

function App() {
  return (
    <div className="app">
            <NavMenu />
    </div>

  );
}

export default App;
