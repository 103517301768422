import React, { useEffect, useState } from 'react';
import axios from 'axios';

const get_color = (number) => {
  if (number < 1 || number > 19) {
    return "Invalid input";
  }

  const color_map = {
    1: "rgba(11, 70, 154, 0.5)",
    2: "rgba(17, 89, 150, 0.5)",
    3: "rgba(33, 95, 162, 0.5)",
    4: "rgba(23, 105, 99, 0.5)",
    5: "rgba(26, 114, 42, 0.5)",
    6: "rgba(67, 100, 20, 0.5)",
    7: "rgba(96, 91, 0, 0.5)",
    8: "rgba(117, 76, 26, 0.5)",
    9: "rgba(142, 87, 23, 0.5)",
    10: "rgba(159, 78, 15, 0.5)",
    11: "rgba(179, 76, 22, 0.5)",
    12: "rgba(174, 47, 26, 0.5)",
    13: "rgba(159, 0, 32, 0.5)",
    14: "rgba(138, 1, 57, 0.5)",
    15: "rgba(120, 37, 133, 0.5)",
    16: "rgba(96, 40, 135, 0.5)",
    17: "rgba(87, 31, 118, 0.5)",
    18: "rgba(65, 43, 136, 0.5)",
    19: "rgba(117, 76, 26, 0.5)",
  };

  return color_map[number];
};

const TreeNode = ({ node, level = 0, path = '' }) => {
  const [expanded, setExpanded] = useState(level < 1);
  const hasChildren = Object.keys(node).some(key => key !== '_value');
  const rootKey = parseInt(path.split('.')[0], 10);
  let transparency = 0.5 - (level * 0.1);
  transparency = transparency < 0.1 ? 0.1 : transparency;
  const backgroundColor = get_color(rootKey, transparency);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div style={{ marginLeft: level * 20, padding: '5px 0' }}>
      <div 
        onClick={hasChildren ? toggleExpand : undefined}
        style={{ cursor: hasChildren ? 'pointer' : 'default', display: 'flex', alignItems: 'center', fontWeight: 'bold', color: '#333' }}
      >
        <span style={{ marginRight: '10px', fontSize: '1.2em' }}>
          {hasChildren && (expanded ? '−' : '+')}
        </span>
        <span style={{ backgroundColor, padding: '5px', borderRadius: '3px' }}>
          {path} {node._value}
        </span>
      </div>
      {expanded && 
        Object.keys(node)
          .filter((key) => key !== '_value')
          .sort((a, b) => {
            const aNum = parseInt(a, 10);
            const bNum = parseInt(b, 10);
            if (!isNaN(aNum) && !isNaN(bNum)) {
              return aNum - bNum;
            }
            return a.localeCompare(b);
          })
          .map((key) => (
            <TreeNode 
              key={key} 
              node={node[key]} 
              level={level + 1} 
              path={`${path}${path ? '.' : ''}${key}`} 
            />
          ))
      }
    </div>
  );
};

const TreeViewer = () => {
  const [treeData, setTreeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTreeData = async () => {
      try {
        const response = await axios.get('http://backend:8008/get_full_tree');
        setTreeData(response.data);
      } catch (error) {
        console.error('Error fetching tree data:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTreeData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading data.</div>;

  return <TreeNode node={treeData} />;
};

export default TreeViewer;
