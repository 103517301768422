// src/components/HomePage.js
import React, { Suspense, lazy } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Import the styles
import '../css/HomePage.css'; // Import the CSS

const SearchBar = lazy(() => import('./SearchBar'));
const CIMSearch = lazy(() => import('./CIMSearch'));
const Footer = lazy(() => import('./Footer'));

const HomePage = ({ activeTab = 0 }) => {
  return (
    <div className="homepage">
      <Tabs defaultIndex={activeTab}>
        <TabList>
          <Tab>CCAM Search</Tab>
          <Tab>CIM Search</Tab>
        </TabList>
        <TabPanel>
          <Suspense fallback={<div>Loading CCAM Search...</div>}>
            <SearchBar />
          </Suspense>
        </TabPanel>
        <TabPanel>
          <Suspense fallback={<div>Loading CIM Search...</div>}>
            <CIMSearch />
          </Suspense>
        </TabPanel>
      </Tabs>
      <Suspense fallback={<div>Loading Footer...</div>}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default HomePage;
